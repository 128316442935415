<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div class="wrapper content">
        <adHorizontal />
        <CalculatorHeader emoji="💵" title="Calculadora de Juros Compostos com Aportes Mensais"
            subtitle="Calcule seu saldo final considerando aportes mensais recorrentes de forma simples." />
        <div class="calculator-body">
            <form @submit.prevent="calculateCompoundInterest">
                <h4>Aportes</h4>
                <div class="form-section">
                    <div class="form-group">
                        <label for="initialValue">Valor Inicial:</label>
                        <input type="text" v-model="formattedInitialValue" @input="onInitialValueInput"
                            id="initialValue" placeholder="R$" required />
                        <caption>Informe o montante inicial</caption>
                    </div>
                    <div class="form-group">
                        <label for="monthlyContribution">Aporte Mensal:</label>
                        <input type="text" v-model="formattedMonthlyContribution" @input="onMonthlyContributionInput"
                            id="monthlyContribution" placeholder="R$" required />
                        <caption>Informe o valor de aportes fixos mensais.</caption>
                    </div>
                </div>
                <h4>Rendimento</h4>
                <div class="form-section">
                    <div class="form-group">
                        <label for="period">Período em meses:</label>
                        <input type="number" max="480" v-model="period" id="period" required />
                        <caption>A quantidade de meses a ser considerada para o cálculo</caption>
                    </div>
                    <div class="form-group">
                        <label for="monthlyInterestRate">Rendimento Mensal (%):</label>
                        <input type="number" step="0.01" v-model="monthlyInterestRate" id="monthlyInterestRate"
                            required />
                        <caption>Informe a taxa de rendimento mensal média estimada dos seus investimentos</caption>
                    </div>
                </div>
                <AppButton label="Calcular" type="primary" size="large" />
            </form>

            <div v-if="resultsVisible" class="results-separator" ref="result">
                <h4>Resultado</h4>
                <hr />
            </div>
            <div v-if="resultsVisible">
                <div class="result">
                    <h5>Total acumulado em {{ period }} meses</h5>
                    <h3>{{ formattedTotalAmount }}</h3>
                </div>
                <div class="results-table-wrapper">
                    <table class="results-table">
                        <thead>
                            <tr>
                                <th>Mês</th>
                                <th><span>Valor Inicial</span></th>
                                <th><span>Rendimentos</span></th>
                                <th><span>Aportes</span></th>
                                <th><span>Valor Final</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(result, index) in results" :key="index">
                                <td><span>{{ index + 1 }}</span></td>
                                <td><span>{{ result.initialValue }}</span></td>
                                <td><span>{{ result.interest }}</span></td>
                                <td><span>{{ result.fixedContribution }}</span></td>
                                <td class="final-value"><span>{{ result.finalValue }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <OtherCalculators :categoryId="2" />
    <div class="calculatorInfo wrapper content">
        <section class="info-block">
            <header>
                <h2>O que são Juros Compostos?</h2>
            </header>
            <p class="large">Os juros compostos são a prática de calcular juros sobre o valor principal e também sobre
                os juros acumulados de períodos anteriores. É um conceito fundamental para o crescimento de
                investimentos ao longo do tempo.</p>
        </section>
        <section class="info-block">
            <header>
                <h2>Como Calcular Juros Compostos?</h2>
            </header>
            <p class="large">Para calcular os juros compostos, usamos a fórmula:
                <code>A = P(1 + r/n)^(nt)</code>, onde:
            <ul>
                <li><strong>P:</strong> Principal inicial</li>
                <li><strong>r:</strong> Taxa de juros anual</li>
                <li><strong>n:</strong> Número de vezes que os juros são compostos por ano</li>
                <li><strong>t:</strong> Tempo em anos</li>
                <li><strong>A:</strong> Montante final</li>
            </ul>
            </p>
        </section>
        <section class="info-block">
            <header>
                <h2>Importância dos Aportes Regulares</h2>
            </header>
            <p class="large">Adicionar aportes regulares ao seu investimento aumenta significativamente o montante
                final. Com os juros compostos, cada aporte adicional cresce de forma exponencial ao longo do tempo,
                potencializando seus ganhos.</p>
        </section>
        <section class="info-block">
            <header>
                <h2>Impacto da Inflação nos Investimentos</h2>
            </header>
            <p class="large">A inflação corrói o poder de compra do dinheiro ao longo do tempo. É essencial considerar a
                taxa de inflação ao planejar investimentos de longo prazo para garantir que os ganhos reais sejam
                positivos.</p>
        </section>
        <section class="info-block">
            <header>
                <h2>Taxa de Administração e seus Efeitos</h2>
            </header>
            <p class="large">A taxa de administração é uma cobrança aplicada por gestores de fundos para administrar os
                investimentos. Embora seja necessária, essa taxa pode impactar os rendimentos finais, e por isso deve
                ser considerada no cálculo dos juros compostos.</p>
        </section>
        <section class="info-block">
            <header>
                <h2>Exemplo Prático de Cálculo</h2>
            </header>
            <p class="large">Suponha que você tenha um valor inicial de R$ 10.000,00, com um aporte mensal de R$
                1.000,00, rendimento mensal de 0,5%, taxa de administração anual de 1% e inflação anual de 3%. Após 12
                meses, o montante final seria o resultado da combinação desses fatores, considerando também os aportes e
                saques extras realizados ao longo do período.</p>
        </section>
        <section class="info-block">
            <header>
                <h2>Dicas para Maximizar os Ganhos</h2>
            </header>
            <p class="large">Para maximizar seus ganhos com juros compostos, considere:
            <ul>
                <li>Fazer aportes regulares e consistentes.</li>
                <li>Optar por investimentos com menores taxas de administração.</li>
                <li>Reinvestir os rendimentos para aproveitar o efeito dos juros compostos.</li>
                <li>Monitorar a inflação e ajustar suas expectativas de rendimento.</li>
            </ul>
            </p>
        </section>
        <section class="info-block">
            <header>
                <h2>Legislação e Atualizações</h2>
            </header>
            <p class="large">Mantenha-se atualizado sobre as mudanças na legislação financeira e econômica, que podem
                impactar diretamente seus investimentos. Consulte fontes oficiais, como o Banco Central e a Receita
                Federal, para obter informações sobre novas regulamentações e alíquotas.</p>
        </section>
    </div>
</template>

<script>
import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
import AppButton from '@/components/common/appButton.vue';
import BreadCrumb from '@/components/layout/breadCrumb.vue';
import OtherCalculators from '@/components/calculator/otherCalculators.vue';
import adHorizontal from '@/components/monetization/adHorizontal.vue';

export default {
    name: 'JurosCompostosAportesRecorrentes',
    components: {
        CalculatorHeader,
        AppButton,
        BreadCrumb,
        OtherCalculators,
        adHorizontal
    },
    data() {
        return {
            breadCrumbItems: [
                { label: 'Calculadora de Juros Compostos com Aportes Recorrentes', url: '/calculadora-juros-compostos-aportes-recorrentes' }
            ],
            initialValue: 0,
            formattedInitialValue: '',
            monthlyContribution: 0,
            formattedMonthlyContribution: '',
            period: 12,
            monthlyInterestRate: 0,
            resultsVisible: false,
            totalAmount: 0,
            formattedTotalAmount: '',
            results: []
        };
    },
    methods: {
        calculateCompoundInterest() {
            const monthlyInterestRateDecimal = this.monthlyInterestRate / 100;
            let currentValue = this.unformatCurrency(this.formattedInitialValue);
            const monthlyContribution = this.unformatCurrency(this.formattedMonthlyContribution);

            let results = [];

            for (let month = 1; month <= this.period; month++) {
                let interest = currentValue * monthlyInterestRateDecimal;
                currentValue = currentValue + interest + monthlyContribution;

                results.push({
                    initialValue: this.formatCurrency(currentValue * 100),
                    interest: this.formatCurrency(interest * 100, true),
                    fixedContribution: this.formatCurrency(monthlyContribution * 100, true),
                    finalValue: this.formatCurrency(currentValue * 100, true)
                });
            }

            this.totalAmount = currentValue;
            this.formattedTotalAmount = this.formatCurrency(this.totalAmount * 100);
            this.results = results;
            this.resultsVisible = true;
            this.scrollToResult();
        },

        onInitialValueInput(event) {
            const value = event.target.value.replace(/\D/g, '');
            this.formattedInitialValue = this.formatCurrency(value);
        },
        onMonthlyContributionInput(event) {
            const value = event.target.value.replace(/\D/g, '');
            this.formattedMonthlyContribution = this.formatCurrency(value);
        },
        formatCurrency(value, noCurrencySymbol = false) {
            if (!noCurrencySymbol){
                let val = (value / 100).toFixed(2).replace('.', ',');
                return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                let val = (value / 100).toFixed(2).replace('.', ',');
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        },
        unformatCurrency(value) {
            return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
        },
        scrollToResult() {
            this.$nextTick(() => {
                const resultSection = this.$refs.result;
                if (resultSection) {
                    resultSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.results-table-wrapper {
    overflow-x: auto;
    width: 100%;
}

.results-table {
    @extend p;
    border-collapse: collapse;
    margin-top: 2rem;
    border: 1px solid $neutral-2;
    border-radius: 20px;
    th,
    td {
        padding: 1rem;
        text-align: right;
        border-bottom: 1px solid #ddd;
        justify-content: end;

        &:nth-child(1) {
            max-width: 48px;

            //Number ribbon
            span {
                background-color: $primary-1;
                color: $primary-8;
                padding: 8px 16px;
                border-radius: 24px;
                font-weight: 600;
                font-family: $titlesFont;
            }
        }

        &:nth-child(7) {
            min-width: 120px;
            text-align: left;

            @include bp(mobile) {
                min-width: 80px;
            }
        }

        &:nth-child(8) {
            min-width: 120px;
            text-align: left;

            @include bp(mobile) {
                min-width: 80px;
            }
        }

        &:nth-child(9) {
            min-width: 120px;
        }


    }

    tr {
        &:nth-child(even) {
            background-color: $neutral-0;
        }
        &:last-of-type {
            td {
                border-bottom: 0;
            }
        }
        &:hover{
            background-color: $primary-0;
            box-shadow: 0 0 8px rgba(0,0,0,0.1);
            z-index: 1000;
        }
    }

    th {
        background-color: $neutral-0;
        padding: 1.2rem;
    }
}

.final-value {
    @extend p, .large;
    color: $primary-6;
    font-weight: 600;
    font-family: $titlesFont;
}
</style>
