<template>
    <BreadCrumb :items="breadCrumbItems" />
    <div class="wrapper content">
      <CalculatorHeader emoji="📄" title="Calculadora de Mini Contratos para Day-Trade"
        subtitle="Calcule o resultado de suas operações de mini índice e mini dólar no day-trade." />
      <div class="calculator-body">
        <form @submit.prevent="calculateTrade">
          <div class="form-section compact">
            <div class="form-group">
              <label for="contractType">Tipo de Contrato:</label>
              <select v-model="contractType" id="contractType" required>
                <option value="miniIndice">Mini Índice</option>
                <option value="miniDolar">Mini Dólar</option>
              </select>
            </div>
            <div class="form-group">
              <label for="operationType">Tipo de Operação:</label>
              <select v-model="operationType" id="operationType" required>
                <option value="compra">Compra</option>
                <option value="venda">Venda</option>
              </select>
            </div>
            <div class="form-group">
              <label for="brokerageFee">Taxa de Corretagem:</label>
              <input type="text" v-model="formattedBrokerageFee" id="brokerageFee" placeholder="R$ 0,00" @input="onBrokerageInput" required />
            </div>

            <div class="form-group">
              <label for="openPrice">Preço de Abertura:</label>
              <input type="number" v-model="openPrice" id="openPrice" placeholder="0" step="0.1" required />
            </div>
            <div class="form-group">
              <label for="closePrice">Preço de Fechamento:</label>
              <input type="number" v-model="closePrice" id="closePrice" placeholder="0" step="0.1" required />
            </div>
            <div class="form-group">
              <label for="quantity">Quantidade de Contratos:</label>
              <input type="number" v-model="quantity" id="quantity" required />
            </div>
          </div>
          <AppButton label="Calcular" type="primary" size="large" />
        </form>
        <div v-if="resultsVisible" class="results-separator">
          <h4>Resultado</h4>
          <hr />
        </div>
        <div v-if="resultsVisible" ref="result" class="flex-group-h">
          <div class="result">
            <h5>Resultado da Operação:</h5>
            <h3 :class="{ positive: financialValue >= 0, negative: financialValue < 0 }">{{ formattedFinancialValue }}</h3>
          </div>
          <div class="result">
            <h5>Pontos:</h5>
            <h3 :class="{ positive: points >= 0, negative: points < 0 }">{{ points }}</h3>
          </div>
        </div>
        <div class="alert-warning">
            <img src="../../assets/images/icons/exclamation-circle-solid.svg" alt="Info">
            <p>Importante: o resultado desta calculadora não considera os descontos com imposto de renda e emolumentos da bolsa de valores.</p>
        </div>
      </div>
    </div>
    <OtherCalculators :categoryId="1" />
    <div class="calculatorInfo wrapper content">
      <section class="info-block">
        <header>
          <h2>Calculadora de Mini Contratos para Day-Trade: Como Funciona?</h2>
        </header>
        <p class="large">A Calculadora de Mini Contratos para Day-Trade foi desenvolvida especialmente para traders que operam no mercado de mini índice e mini dólar, visando facilitar a análise rápida do resultado financeiro e da pontuação das operações realizadas. Ao informar o tipo de contrato (mini índice ou mini dólar), a quantidade de contratos, tipo de operação (compra ou venda) e os preços de abertura e fechamento, a calculadora apresenta o lucro ou perda em pontos e o resultado financeiro exato da operação.</p>
        <p class="large">O cálculo é simples e baseado nas principais variáveis do mercado de day-trade, permitindo ao trader realizar uma análise mais precisa sobre os ganhos ou perdas de cada negociação. A ferramenta facilita o planejamento de futuras operações e a definição de estratégias baseadas nos resultados anteriores.</p>
    </section>
        <section class="info-block">
        <header>
          <h3>O que é um Mini Contrato?</h3>
        </header>
        <p class="large">No mercado financeiro, os mini contratos, como o mini índice e o mini dólar, são tipos de derivativos que permitem a negociação de pequenas frações de contratos padrão. Isso torna as operações mais acessíveis, especialmente para quem faz day-trade, onde a compra e venda de ativos ocorrem no mesmo dia. Essa modalidade exige precisão nos cálculos, sendo essencial entender o impacto financeiro de cada ponto ganho ou perdido nas operações.</p>
        </section>
        <section class="info-block">
        <header>
          <h3>Como Utilizar a Calculadora?</h3>
        </header>
        <ul>
          <li><strong>Escolha o Tipo de Contrato:</strong> Opte entre mini índice ou mini dólar, dependendo do ativo que está operando.</li>
          <li><strong>Selecione a Operação:</strong> Indique se é uma operação de compra ou venda, pois isso altera a forma de cálculo dos pontos.</li>
          <li><strong>Insira os Valores:</strong> Informe a quantidade de contratos, o preço de abertura e o preço de fechamento da operação.</li>
          <li><strong>Obtenha o Resultado:</strong> Clique em calcular para visualizar a quantidade de pontos e o resultado financeiro.</li>
        </ul>
        </section>
        <section class="info-block">
        <header>
          <h3>Benefícios de Utilizar a Calculadora de Mini Contratos para Day-Trade</h3>
        </header>
        <p class="large">A ferramenta permite maior segurança e rapidez na verificação de resultados de day-trade, um mercado onde decisões precisam ser tomadas em segundos. Ao fornecer um cálculo exato de pontos e valores financeiros, o trader consegue ajustar suas estratégias com base em dados confiáveis, maximizando os lucros e minimizando as perdas. A Calculadora de Mini Contratos para Day-Trade é uma aliada na gestão de risco, ajudando a melhorar o desempenho de qualquer trader que busque operar de forma mais informada.</p>
      </section>
    </div>
  </template>
  
  <script>
  import CalculatorHeader from '@/components/layout/calculatorHeader.vue';
  import AppButton from '@/components/common/appButton.vue';
  import BreadCrumb from '@/components/layout/breadCrumb.vue';
  import OtherCalculators from '@/components/calculator/otherCalculators.vue';
  
  export default {
    name: 'MiniContractsDayTrade',
    components: {
      CalculatorHeader,
      AppButton,
      BreadCrumb,
      OtherCalculators
    },
    data() {
      return {
        breadCrumbItems: [
          { label: 'Calculadora de Mini Contratos Day-Trade', url: '/calculadora-mini-contratos-day-trade' }
        ],
        contractType: 'miniIndice',
        operationType: 'compra',
        quantity: 1,
        openPrice: '',
        closePrice: '',
        points: 0,
        brokerageFee: 0,
        financialValue: 0,
        resultsVisible: false,
        formattedBrokerageFee: 'R$ 0,00'
      };
    },
    computed: {
      formattedFinancialValue() {
        return this.formatCurrency(this.financialValue * 100); // Multiplica por 100 para adaptar ao formato de centavos
      }
    },
    methods: {
      calculateTrade() {
        // Calcula a quantidade de pontos com base no tipo de operação
        const pontos = this.operationType === 'compra'
          ? this.closePrice - this.openPrice
          : this.openPrice - this.closePrice;
    
        // Define o multiplicador de acordo com o tipo de contrato
        const multiplier = this.contractType === 'miniIndice' ? 0.20 : 10;
        const brokerage = this.unformatCurrency(this.formattedBrokerageFee);
        const totalBrokerage = brokerage * this.quantity;
    
        // Calcula e define os resultados
        this.points = pontos;
        this.financialValue = pontos * this.quantity * multiplier - totalBrokerage;
        this.resultsVisible = true;
        this.scrollToResult();
      },
      onBrokerageInput(event) {
        const value = event.target.value.replace(/\D/g, '');
        this.formattedBrokerageFee = this.formatCurrency(value);
      },
      formatCurrency(value) {
        let val = (value / 100).toFixed(2).replace('.', ',');
        return 'R$ ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
      unformatCurrency(value) {
        return parseFloat(value.replace('R$', '').replace(/\./g, '').replace(',', '.'));
      },
      scrollToResult() {
        const resultSection = this.$refs.result;
        if (resultSection) {
          resultSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>

  </style>
  