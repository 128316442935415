<template>
    <div class="not-found">
        <img src="@/assets/images/icons/sad-cry.svg" alt="">
      <h1>Página não encontrada</h1>
      <p>Ops! A página que você está procurando não existe.</p>
    <AppButton label="Voltar para a Home" type="secondary" size="medium" to="/"/>
    </div>
  </template>
  
  <script>
import AppButton from '@/components/common/appButton.vue';
  export default {
    name: 'NotFound',
    components: {
      AppButton
    }
  };
  </script>
  
  <style scoped>
  .not-found {
    text-align: center;
    padding: 8rem 0 12rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap:1.2rem;
    max-width: 1200px;
    margin:2rem;
  }
  h1 {
    font-size: 4rem;
  }
  p {
    margin-bottom: 2rem
  }
  </style>
  