import { createStore } from 'vuex';

export default createStore({
  state: {
    categories: [
        {
          id: 1,
          name: "Calculadoras Trabalhistas",
          subtitle: "Calcule INSS, FGTS, outros impostos e descontos trabalhistas.",
          icon: require('@/assets/images/icons/user-worker.svg'),
          calculators: [
            { id: 1, emoji: '🤑', title: 'Calculadora de Salário Líquido CLT', description: 'Calcule o valor exato que você recebe após descontos como impostos e contribuições. Facilite seu planejamento financeiro.', pageUrl: '/calculadora-salario-liquido-clt' },
            //{ id: 2, emoji: '💼', title: 'Calculadora de Salário Líquido CLT e PJ', description: 'Compare o salário líquido entre CLT e PJ para fazer a melhor escolha.', pageUrl: '/calculadora-salario-liquido-clt-pj' },
            //{ id: 35, emoji: '💼', title: 'Calculadora de INSS', description: 'Calcule o valor do INSS a ser descontado do seu salário.', pageUrl: '/calculadora-inss' },
            { id: 36, emoji: '🏦', title: 'Calculadora de FGTS', description: 'Calcule o saldo do FGTS ao final do seu contrato de trabalho considerando os depósitos mensais e os juros anuais da Caixa Econômica Federal.', pageUrl: '/calculadora-fgts' },
            //{ id: 37, emoji: '💰', title: 'Calculadora de Imposto de Renda', description: 'Calcule o valor do Imposto de Renda a ser pago.', pageUrl: '/calculadora-imposto-renda' },
            //{ id: 38, emoji: '📄', title: 'Calculadora de Rescisão de Contrato de Trabalho CLT', description: 'Calcule o valor da rescisão do contrato de trabalho.', pageUrl: '/calculadora-rescisao-clt' },
            //{ id: 39, emoji: '💵', title: 'Calculadora de ISS', description: 'Calcule o valor do ISS a ser pago.', pageUrl: '/calculadora-iss' },
            //{ id: 40, emoji: '💳', title: 'Calculadora de PIS/PASEP', description: 'Calcule o valor do PIS/PASEP a ser pago.', pageUrl: '/calculadora-pis-pasep' }
            { id: 45, emoji: '⏰', title: 'Calculadora de Horas Extras CLT', description: 'Calcule o valor das suas horas extras com base no seu salário e no percentual adicional para hora extra noturna e outros adicionais.', pageUrl: '/calculadora-horas-extras-clt'}
          ]
        },
        {
          id: 2,
          name: "Calculadoras Financeiras",
          subtitle: "Calcule juros compostos, investimentos e planeje sua vida financeira.",
          icon: require('@/assets/images/icons/money-bill-wave-solid.svg'),
          calculators: [
            { id: 3, emoji: '📈', title: 'Calculadora de Juros Compostos', description: 'Calcule o valor dos seus investimentos com juros compostos ao longo do tempo.', pageUrl: '/calculadora-juros-compostos' },
            { id: 4, emoji: '💵', title: 'Calculadora de Juros Compostos com Aportes Mensais', description: 'Simule seus investimentos com aportes recorrentes mensais e juros compostos.', pageUrl: '/calculadora-juros-compostos-aportes-mensais' },
            { id: 5, emoji: '💰', title: 'Calculadora de Juros Compostos com Aportes e Retiradas', description: 'Calcule os juros compostos com aportes e saques, mês a mês.', pageUrl: '/calculadora-juros-compostos-aportes-saques' },
            //{ id: 6, emoji: '🚗', title: 'Calculadora de Financiamento de Veículos', description: 'Calcule o valor das parcelas do seu financiamento de veículo.', pageUrl: '/calculadora-financiamento-veiculos' },
            //{ id: 7, emoji: '🏡', title: 'Calculadora de Financiamento Imobiliário', description: 'Calcule o valor das parcelas do seu financiamento imobiliário.', pageUrl: '/calculadora-financiamento-imobiliario' },
            //{ id: 8, emoji: '📊', title: 'Calculadora de Economia para Aposentadoria', description: 'Planeje sua aposentadoria calculando quanto você precisa economizar.', pageUrl: '/calculadora-economia-aposentadoria' },
            //{ id: 9, emoji: '💸', title: 'Calculadora de Rendimento da Poupança', description: 'Calcule o rendimento dos seus investimentos na poupança.', pageUrl: '/calculadora-rendimento-poupanca' },
            { id: 10, emoji: '📄', title: 'Calculadora de Mini Contratos Day Trade', description: 'Calcule seus ganhos e perdas em mini contratos de day trade.', pageUrl: '/calculadora-mini-contratos-day-trade' },
            //{ id: 11, emoji: '📈', title: 'Calculadora de Inflação e Correção Monetária', description: 'Calcule a correção monetária dos seus investimentos com base na inflação.', pageUrl: '/calculadora-inflacao-correcao-monetaria' }
          ]
        },
        {
          id: 3,
          name: "Calculadoras de Saúde e Bem-Estar",
          subtitle: "Mantenha-se em forma com nossas calculadoras de saúde.",
          icon: require('@/assets/images/icons/apple-alt-solid.svg'),
          calculators: [
            { id: 24, emoji: '⚖️', title: 'Calculadora de IMC', description: 'Calcule seu Índice de Massa Corporal (IMC), adulto e infantil, de forma rápida e fácil.', pageUrl: '/calculadora-imc' },
            { id: 25, emoji: '🔥', title: 'Calculadora de Taxa Metabólica Basal (TMB)', description: 'Calcule sua taxa metabólica basal de forma fácil.', pageUrl: '/calculadora-tmb' },
            //{ id: 26, emoji: '🍽️', title: 'Calculadora de Consumo Calórico Diário', description: 'Calcule o consumo calórico diário necessário para manter seu peso.', pageUrl: '/calculadora-consumo-calorico' },
            { id: 27, emoji: '💓', title: 'Calculadora de Frequência Cardíaca Ideal', description: 'Calcule sua frequência cardíaca ideal para atividades físicas.', pageUrl: '/calculadora-frequencia-cardiaca' },
            //{ id: 28, emoji: '⚖️', title: 'Calculadora de Peso Ideal', description: 'Calcule seu peso ideal com base na sua altura e estrutura corporal.', pageUrl: '/calculadora-peso-ideal' },
            //{ id: 29, emoji: '🏋️', title: 'Calculadora de Percentual de Gordura Corporal', description: 'Calcule seu percentual de gordura corporal.', pageUrl: '/calculadora-percentual-gordura' }
          ]
        },
        /*{
          id: 4,
          name: "Calculadoras de Utilidades Diárias",
          subtitle: "Otimize o uso de recursos com nossas calculadoras de utilidades e consumo.",
          icon: require('@/assets/images/icons/utilities.svg'),
          calculators: [
            { id: 12, emoji: '🔋', title: 'Calculadora de Consumo de Energia Elétrica', description: 'Calcule o consumo de energia elétrica dos seus aparelhos.', pageUrl: '/calculadora-consumo-energia' },
            { id: 13, emoji: '💧', title: 'Calculadora de Consumo de Água', description: 'Calcule o consumo de água na sua residência.', pageUrl: '/calculadora-consumo-agua' },
            { id: 14, emoji: '⛽', title: 'Calculadora de Consumo de Combustível', description: 'Calcule o consumo de combustível do seu veículo.', pageUrl: '/calculadora-consumo-combustivel' },
            { id: 15, emoji: '🛣️', title: 'Calculadora de Tempo de Viagem', description: 'Calcule o tempo estimado para sua viagem.', pageUrl: '/calculadora-tempo-viagem' },
            { id: 16, emoji: '🚕', title: 'Calculadora de Comparação Uber, Táxi e Carro', description: 'Compare os custos de viagem entre Uber, táxi e carro próprio.', pageUrl: '/calculadora-comparacao-uber-taxi-carro' }
          ]
        },
        {
          id: 5,
          name: "Calculadoras de Negócios e Empreendedorismo",
          subtitle: "Facilite tarefas do seu negócio com nossas calculadoras empresariais.",
          icon: require('@/assets/images/icons/briefcase.svg'),
          calculators: [
            { id: 30, emoji: '💸', title: 'Calculadora de Fluxo de Caixa', description: 'Calcule o fluxo de caixa do seu negócio.', pageUrl: '/calculadora-fluxo-caixa' },
            { id: 31, emoji: '🔖', title: 'Calculadora de Markup', description: 'Calcule o markup necessário para precificar seus produtos.', pageUrl: '/calculadora-markup' },
            { id: 32, emoji: '⚖️', title: 'Calculadora de Ponto de Equilíbrio', description: 'Calcule o ponto de equilíbrio do seu negócio.', pageUrl: '/calculadora-ponto-equilibrio' },
            { id: 33, emoji: '💼', title: 'Calculadora de Lucro Líquido', description: 'Calcule o lucro líquido do seu negócio.', pageUrl: '/calculadora-lucro-liquido' },
            { id: 34, emoji: '💼', title: 'Calculadora de Margem de Contribuição', description: 'Calcule a margem de contribuição dos seus produtos.', pageUrl: '/calculadora-margem-contribuicao' }
          ]
        },
        {
          id: 6,
          name: "Calculadoras de Investimentos",
          subtitle: "Maximize seus investimentos com nossas calculadoras.",
          icon: require('@/assets/images/icons/pie-chart.svg'),
          calculators: [
            { id: 41, emoji: '📈', title: 'Calculadora de Retorno de Investimento (ROI)', description: 'Calcule o retorno sobre o investimento.', pageUrl: '/calculadora-roi' },
            { id: 42, emoji: '🔍', title: 'Calculadora de Custo-Benefício', description: 'Calcule o custo-benefício dos seus investimentos.', pageUrl: '/calculadora-custo-beneficio' },
            { id: 43, emoji: '💼', title: 'Calculadora de Alocação de Ativos', description: 'Calcule a alocação ideal dos seus ativos.', pageUrl: '/calculadora-alocacao-ativos' },
            { id: 44, emoji: '📊', title: 'Calculadora de Taxa Interna de Retorno (TIR)', description: 'Calcule a taxa interna de retorno dos seus investimentos.', pageUrl: '/calculadora-tir' }
          ]
        },
        {
          id: 7,
          name: "Calculadoras de Educação e Estudos",
          subtitle: "Simplifique seus cálculos de notas e tempo de estudo.",
          icon: require('@/assets/images/icons/book-education.svg'),
          calculators: [
            { id: 17, emoji: '📝', title: 'Calculadora de Notas (média aritmética e média ponderada)', description: 'Calcule a média das suas notas de forma fácil.', pageUrl: '/calculadora-notas' },
            { id: 18, emoji: '📚', title: 'Calculadora de Tempo de Estudo Necessário', description: 'Planeje seu tempo de estudo com eficiência.', pageUrl: '/calculadora-tempo-estudo' },
            { id: 19, emoji: '🏫', title: 'Calculadora de GPA (Grade Point Average)', description: 'Calcule o seu GPA de forma fácil.', pageUrl: '/calculadora-gpa' },
            { id: 20, emoji: '💳', title: 'Calculadora de Crédito Universitário', description: 'Calcule o valor do seu crédito universitário.', pageUrl: '/calculadora-credito-universitario' }
          ]
        },
        {
          id: 8,
          name: "Calculadoras de Construção e Reforma",
          subtitle: "Planeje sua construção, calcule materiais e custos da obra.",
          icon: require('@/assets/images/icons/ruler.svg'),
          calculators: [
            { id: 21, emoji: '🔧', title: 'Calculadora de Material de Construção', description: 'Calcule a quantidade de material necessário para sua construção.', pageUrl: '/calculadora-material-construcao' },
            { id: 22, emoji: '🛠️', title: 'Calculadora de Custo de Reforma', description: 'Calcule o custo total da sua reforma.', pageUrl: '/calculadora-custo-reforma' },
            { id: 23, emoji: '📐', title: 'Calculadora de Área e Volume', description: 'Calcule a área e o volume de diferentes formas geométricas.', pageUrl: '/calculadora-area-volume' }
          ]
        }*/
      ]
  },
  getters: {
    
    filteredCategories: (state) => (searchQuery) => {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
  
      const query = removeAccents(searchQuery.toLowerCase());
  
      return state.categories.map(category => {
        const categoryMatch = removeAccents(category.name.toLowerCase()).includes(query) || removeAccents(category.subtitle.toLowerCase()).includes(query);
  
        const filteredCalculators = category.calculators.filter(calculator => {
          const title = removeAccents(calculator.title.toLowerCase());
          const description = removeAccents(calculator.description.toLowerCase());
          return title.includes(query) || description.includes(query);
        });
  
        if (categoryMatch || filteredCalculators.length > 0) {
          return {
            ...category,
            calculators: categoryMatch ? category.calculators : filteredCalculators
          };
        }
        
        return null;
      }).filter(category => category !== null);
    },

    getOtherCalculators: (state) => (categoryId) => {
      const category = state.categories.find(category => category.id === categoryId);
      return category ? category.calculators : [];
    }
  }
});