<template>
  <button :class="buttonClasses" @click="handleClick">
    <i v-if="iconLeftSrc" class="icon-left">
      <img :src="iconLeftSrc" alt="">
    </i>
    <span>{{ label }}</span>
    <i v-if="iconRightSrc" class="icon-right">
      <img :src="iconRightSrc" alt="">
    </i>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    label: {
      type: String,
      default: ''
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value)
    },
    type: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary', 'outline'].includes(value)
    },
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    scrollToTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      return [
        'app-button',
        `app-button--${this.size}`,
        `app-button--${this.type}`,
        this.label=='' ? `only-icon` : ''
      ];
    },
    iconLeftSrc() {
      return this.iconLeft ? require(`@/assets/images/icons/${this.iconLeft}`) : null;
    },
    iconRightSrc() {
      return this.iconRight ? require(`@/assets/images/icons/${this.iconRight}`) : null;
    }
  },
  methods: {
    handleClick(event) {
      if (this.scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (this.to) {
        this.$router.push(this.to);
      } else if (this.href) {
        window.open(this.href, '_blank');
      }
      this.$emit('click', event);
    }
  }
};
</script>

<style scoped lang="scss">
.app-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $bodyFont;
  border: none;
  border-radius: .8rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.app-button--small {
  font-size: 1.4rem;
  padding: 1.2rem 1.6rem;
}

.app-button--medium {
  font-size: 1.6rem;
  padding: 1.6rem 2rem;
}

.app-button--large {
  font-size: 1.8rem;
  padding: 2rem 2.4rem;

  @include bp(mobile) {
    font-size: 1.6rem;
    padding: 1.6rem 2rem;
  }
}

.app-button--primary {
  background-color: $primary-5;
  color: $white;
}

.app-button--secondary {
  background-color: $primary-0;
  color: $primary-7;
}

.app-button--outline {
  background-color: transparent;
  color: $primary-6;
  border: 2px solid $primary-6;

  &:hover {
    background-color: $primary-6;
    color: white;
  }
}

.app-button--primary:hover {
  background-color: $primary-6;
}

.app-button--secondary:hover {
  background-color: $primary-1;
}

.only-icon{
  .icon-right, .icon-left{
    margin:0;
    display: flex;
  }
}

i.icon-left {
  margin-right: 0.5rem;
}

i.icon-right {
  margin-left: 0.5rem;
}
</style>

<!--<template>
  <button :class="buttonClasses" @click="handleClick">
    <i v-if="iconLeft" class="icon-left">
      <img :src=iconLeft alt="">
    </i>
    <span>{{ label }}</span>
    <i v-if="iconRight" class="icon-right">
      <img :src=iconRight alt="">
    </i>
  </button>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    label: {
      type: String,
      required: true
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value)
    },
    type: {
      type: String,
      default: 'primary',
      validator: value => ['primary', 'secondary', 'outline'].includes(value)
    },
    to: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    scrollToTop: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      return [
        'app-button',
        `app-button--${this.size}`,
        `app-button--${this.type}`
      ];
    }
  },
  methods: {
    handleClick(event) {
      if (this.scrollToTop) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (this.to) {
        this.$router.push(this.to);
      } else if (this.href) {
        window.open(this.href, '_blank');
      }
      this.$emit('click', event);
    }
  }
};
</script>

<style scoped lang="scss">
.app-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $bodyFont;
  border: none;
  border-radius: .8rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.app-button--small {
  font-size: 1.4rem;
  padding: 1.2rem 1.6rem;
}

.app-button--medium {
  font-size: 1.6rem;
  padding: 1.6rem 2rem;
}

.app-button--large {
  font-size: 1.8rem;
  padding: 2rem 2.4rem;

  @include bp(mobile) {
    font-size: 1.6rem;
    padding: 1.6rem 2rem;
  }
}

.app-button--primary {
  background-color: $primary-5;
  color:$white;
}

.app-button--secondary {
  background-color:$primary-0;
  color:$primary-7;
}

.app-button--outline {
  background-color: transparent;
  color: $primary-6;
  border: 2px solid $primary-6;

  &:hover {
    background-color: $primary-6;
    color: white;
  }
}

.app-button--primary:hover {
  background-color: $primary-6;
}

.app-button--secondary:hover {
  background-color: $primary-1;
}

i.icon-left {
  margin-right: 0.5rem;
}

i.icon-right {
  margin-left: 0.5rem;
}

</style>
-->