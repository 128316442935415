import { createRouter, createWebHistory } from 'vue-router';

// Import your components
import AppHome from '../views/Home.vue';
import AboutPage from '../views/About.vue';
import ContactPage from '../views/Contact.vue';
import PrivacyPolicy from '../views/Privacy.vue';
import TermsOfUse from '../views/Terms.vue';
import SalarioLiquidoCLT from '../views/calculators/SalarioLiquidoCLT.vue';
import CalculadoraIMC from '../views/calculators/CalculadoraIMC.vue';
import CalculadoraTMB from '../views/calculators/CalculadoraTMB.vue';
import CalculadoraFCIdeal from '../views/calculators/CalculadoraFCIdeal.vue';
import JurosCompostosAportesSaques from '../views/calculators/JurosCompostosAportesSaques.vue';
import JurosCompostosAportesMensais from '../views/calculators/JurosCompostosAportesMensais.vue';
import JurosCompostos from '../views/calculators/JurosCompostos.vue';
import CalculadoraFGTS from '../views/calculators/CalculadoraFGTS.vue';
import HoraExtraCLT from '../views/calculators/HorasExtrasCLT.vue';
import MiniContratos from '@/views/calculators/MiniContratos.vue';
import NotFound from '../views/NotFound.vue';
//import BlogPost from '../views/BlogPost.vue';

const routes = [
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: NotFound },
  {
    path: '/',
    name: 'Home',
    component: AppHome,
    meta: {
      title: 'Calculico - Calculadoras Online Para Tudo Que Você Precisa',
      description: 'Explore uma vasta gama de calculadoras online para facilitar suas tarefas diárias, planejamento financeiro, saúde e muito mais. Calculico é sua ferramenta de cálculo confiável para 2024 e além.',
      keywords: 'calculadoras online, planejamento financeiro, saúde, ferramentas de cálculo, Calculico, 2024'
    }
  },
  {
    path: '/sobre',
    name: 'About',
    component: AboutPage,
    meta: {
      title: 'Sobre o Calculico - Calculadoras Online para Tudo Que Você Precisa',
      description: 'Descubra mais sobre o Calculico, uma plataforma inovadora dedicada a fornecer calculadoras online para diversas necessidades, desde finanças pessoais até saúde.',
      keywords: 'sobre o Calculico, calculadoras online, finanças pessoais, saúde, ferramentas de cálculo, 2024'
    }
  },
  {
    path: '/contato',
    name: 'Contact',
    component: ContactPage,
    meta: {
      title: 'Fale Conosco | Calculico',
      description: 'Entre em contato com a equipe do Calculico. Estamos aqui para fornecer suporte e responder suas perguntas sobre nossas calculadoras online.',
      keywords: 'fale com o Calculico, contato, suporte, calculadoras online, finanças pessoais, saúde, ferramentas de cálculo'
    }
  },
  {
    path: '/privacidade',
    name: 'Privacy',
    component: PrivacyPolicy,
    meta: {
      title: 'Política de Privacidade | Calculico',
      description: 'Leia nossa Política de Privacidade para entender como o Calculico protege e gerencia suas informações pessoais de forma segura e confiável.',
      keywords: 'política de privacidade, proteção de dados, Calculico, informações pessoais, segurança, 2024'
    }
  },
  {
    path: '/termos',
    name: 'Terms',
    component: TermsOfUse,
    meta: {
      title: 'Termos de Uso | Calculico',
      description: 'Conheça os Termos de Uso do Calculico e saiba como utilizamos nossos serviços de calculadoras online para proporcionar a melhor experiência ao usuário.',
      keywords: 'termos de uso, condições de uso, Calculico, serviços de calculadoras, regulamento, 2024'
    }
  },
  /**{
    path: '/conteudo/:slug',
    name: 'BlogPost',
    component: BlogPost,
    meta: {
      title: 'Post do Blog | Calculico',
      description: 'Leia nosso post do blog e descubra dicas, notícias e informações úteis sobre finanças pessoais, saúde e muito mais. Fique por dentro das últimas novidades com o Calculico.',
      keywords: 'post do blog, finanças pessoais, saúde, dicas, notícias, informações, Calculico, 2024'
    }
  },**/
  {
    path: '/calculadora-salario-liquido-clt',
    name: 'Calculadora Salario Liquido CLT',
    component: SalarioLiquidoCLT,
    meta: {
      title: 'Calculadora de Salário Líquido CLT 2024 | Calculico',
      description: 'Calcule seu salário líquido de forma precisa com nossa Calculadora de Salário Líquido CLT. Descubra o valor exato que você recebe após todos os descontos aplicáveis.',
      keywords: 'calculadora de salário líquido, CLT, cálculo de salário, descontos, planejamento financeiro, 2024'
    }
  },
  {
    path: '/calculadora-fgts',
    name: 'Calculadora FGTS',
    component: CalculadoraFGTS,
    meta: {
      title: 'Calculadora de FGTS | Calculico',
      description: 'Calcule o valor do seu FGTS atualizado e descubra quanto você tem disponível para saque. Utilize nossa Calculadora de FGTS para planejar seu futuro financeiro.',
      keywords: 'calculadora de FGTS, Fundo de Garantia, cálculo de FGTS, saque, planejamento financeiro, 2024',
    }
  },
  {
    path: '/calculadora-horas-extras-clt',
    name: 'Calculadora Hora Extra',
    component: HoraExtraCLT,
    meta: {
      title: 'Calculadora de Horas Extras CLT 2024 | Calculico',
      description: 'Calcule o valor da sua hora extra de acordo com a legislação trabalhista vigente. Utilize nossa Calculadora de Hora Extra CLT para planejar seus ganhos adicionais.',
      keywords: 'calculadora de hora extra, CLT, cálculo de hora extra, legislação trabalhista, planejamento financeiro, 2024'
    }
  },
  {
    path: '/calculadora-imc',
    name: 'Calculadora de IMC',
    component: CalculadoraIMC,
    meta: {
      title: 'Calculadora de IMC Adulto e Infantil | Calculico',
      description: 'Calcule seu Índice de Massa Corporal (IMC) com nossa Calculadora de IMC e descubra se você está no peso ideal para a sua saúde.',
      keywords: 'calculadora de IMC, índice de massa corporal, cálculo de IMC, peso ideal, saúde, 2024'
    }
  },
  {
    path: '/calculadora-tmb',
    name: 'Calculadora TMB',
    component: CalculadoraTMB,
    meta: {
      title: 'Calculadora TMB - Taxa Metabólica Basal | Calculico',
      description: 'Calcule sua Taxa Metabólica Basal (TMB) com nossa Calculadora TMB e saiba quantas calorias seu corpo precisa em repouso.',
      keywords: 'calculadora TMB, taxa metabólica basal, cálculo de TMB, calorias em repouso, saúde, 2024'
    }
  },
  {
    path: '/calculadora-frequencia-cardiaca',
    name: 'Calculadora de Frequência Cardíaca Máxima Ideal',
    component: CalculadoraFCIdeal,
    meta: {
      title: 'Calculadora de Frequência Cardíaca Máxima Ideal | Calculico',
      description: 'Descubra sua frequência cardíaca máxima ideal para diferentes intensidades de exercício com nossa Calculadora de Frequência Cardíaca Ideal.',
      keywords: 'calculadora de frequência cardíaca, frequência cardíaca ideal, exercícios, saúde, fitness, 2024'
    }
  },
  {
    path: '/calculadora-juros-compostos-aportes-saques',
    name: 'Calculadora de Juros Compostos com Aportes e Retiradas',
    component: JurosCompostosAportesSaques,
    meta: {
      title: 'Calculadora de Juros Compostos com Aportes e Retiradas Mês a Mês | Calculico',
      description: 'Calcule seus ganhos com juros compostos considerando aportes recorrentes e saques ou retiradas mês a mês. Otimize seus investimentos com precisão.',
      keywords: 'calculadora de juros compostos, aportes, retiradas, saques, cálculo de juros, investimentos, 2024'
    }
  },
  {
    path: '/calculadora-juros-compostos-aportes-mensais',
    name: 'Calculadora de Juros Compostos com Aportes Mensais',
    component: JurosCompostosAportesMensais,
    meta: {
      title: 'Calculadora de Juros Compostos com Aportes Recorrentes | Calculico',
      description: 'Calcule seus ganhos com juros compostos considerando aportes recorrentes. Otimize seus investimentos com precisão.',
      keywords: 'calculadora de juros compostos, aportes, cálculo de juros, investimentos, 2024'
    }
  },
  {
    path: '/calculadora-juros-compostos',
    name: 'Calculadora de Juros Compostos',
    component: JurosCompostos,
    meta: {
      title: 'Calculadora de Juros Compostos | Calculico',
      description: 'Calcule seus ganhos com juros compostos de forma simples e precisa. Descubra o poder dos investimentos a longo prazo.',
      keywords: 'calculadora de juros compostos, cálculo de juros, investimentos, rendimentos, 2024'
    }
  },
  {
    path: '/calculadora-mini-contratos-day-trade',
    name: 'Calculadora de Mini Contratos para Day-Trade',
    component: MiniContratos,
    meta: {
      title: 'Calculadora de Mini Contratos para Day-Trade | Calculico',
      description: 'Use a Calculadora de Mini Contratos para Day-Trade e calcule o lucro ou perda de suas operações de mini índice e mini dólar. Insira tipo de operação, quantidade de contratos e preços para obter resultados financeiros precisos.',
      keywords: 'calculadora de mini contratos, day-trade, mini índice, mini dólar, cálculo de day-trade, lucro e perda day-trade, calculadora financeira para traders, cálculo de pontos mini índice, cálculo financeiro mini dólar'
    }
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        top: 0
      };
    }
  }
});

export default router;
